*,
::before,
::after {
	box-sizing: border-box;
}

:root {
	background: #fffff8;
	color: #11111a;
	font: normal 1em/1.42 system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
	height: 100%;
	overflow-y: scroll;
}

body {
	font-size: 1.125em;
	margin: 0;
	height: 100%;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
}
